<script setup lang="ts">
import { computed } from 'vue';
import { useAuth } from '../composables/useAuth';
import AtlasProgressBar from '#atlaskit/components/AtlasProgressBar.vue';
import { useDetails } from '../composables/useDetails';

const { query } = useAuth();
const loading = computed(() => query.isLoading.value);
const success = computed(() => query.isSuccess.value);

// Allows identifying the user's details
useDetails();
</script>

<template>
  <div
    v-if="loading"
    class="progres-bar-container"
  >
    <AtlasProgressBar />
  </div>
  <slot v-if="success" />
</template>

<style scoped>
.progres-bar-container {
  margin-top: 33vh;
}
</style>
