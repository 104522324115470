import { computed, ref } from 'vue';

const jwtToken = ref('');

export const useJWT = () => {
  const setToken = (token: string) => {
    jwtToken.value = token;
  };

  const getToken = () => {
    const token = jwtToken.value;

    return `JWT ${token}`;
  };

  const removeToken = () => {
    jwtToken.value = '';
  };

  const hasToken = computed(() => !!jwtToken.value);

  return { setToken, getToken, removeToken, hasToken };
};
