<template>
  <div v-if="isValidDomain === false">
    <InvalidDomain />
  </div>
  <div v-else-if="store.auth.state.isLoading">
    <Loading />
  </div>
  <div v-else-if="shouldAllowAccess === false">
    <Access />
  </div>
  <div v-else-if="isLicensed === false">
    <ExpiredLicense />
  </div>
  <div
    v-else-if="authToken"
    class="min-h-screen flex bg-white"
  >
    <Loading v-if="loadingStatus !== 0" />
    <ErrorNotification
      :message="errorMessage"
      :show-notification="showNotification"
      @hide-notification="hideNotification(false)"
    />
    <Sidebar v-if="includeSidebar && showSidebar" />
    <slot />
  </div>
</template>

<script>
import Access from '@/components/settings/Access.vue';
import ErrorNotification from './ErrorNotification.vue';
import ExpiredLicense from '@/components/atlassian/ExpiredLicense.vue';
import InvalidDomain from '@/components/atlassian/InvalidDomain.vue';
import Loading from './Loading.vue';
import Sidebar from './Sidebar.vue';
import utils from '@/utils';

import { useRoute } from 'vue-router';
import { computed, inject, onMounted, ref, watch } from 'vue';

export default {
  components: {
    Access,
    ExpiredLicense,
    InvalidDomain,
    Loading,
    Sidebar,
    ErrorNotification,
  },
  props: {
    includeSidebar: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldAuthenticate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const store = inject('store');
    const route = useRoute();
    const queryParams = computed(() => route.query);

    onMounted(async () => {
      if (props.shouldAuthenticate === true) {
        store.auth.methods.setQueryParams(queryParams.value);
        if (store.auth.state.authentication.auth_token === '') {
          await store?.auth.methods.authenticate();
        }
      }
    });

    const authToken = computed({
      get() {
        return store.auth.state.authentication.auth_token;
      },
    });

    const isLicensed = computed({
      get() {
        return store.auth.state.authentication.is_licensed;
      },
    });

    const shouldAllowAccess = computed({
      get() {
        return store.auth.methods.shouldAllowAccess();
      },
    });

    const isValidDomain = computed({
      get() {
        return utils.shouldAllowRootDomain();
      },
    });

    const loadingStatus = computed({
      get() {
        return store.loading.state.loadingStatus;
      },
    });

    const showNotification = computed({
      get() {
        return store.loading.state.errorNotification;
      },
    });

    const errorMessage = computed({
      get() {
        return store.loading.state.errorMessage;
      },
    });

    const hideNotification = () => {
      store.loading.methods.setError(false);
    };

    const showSidebar = ref(false);
    setTimeout(() => {
      showSidebar.value = true;
    }, 100);

    return {
      loadingStatus,
      showNotification,
      hideNotification,
      errorMessage,
      showSidebar,
      isValidDomain,
      store,
      isLicensed,
      authToken,
      shouldAllowAccess,
    };
  },
};
</script>
