<script setup lang="ts">
import ProgressBar from '@atlaskit/progress-bar';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

withDefaults(
  defineProps<{
    indeterminate?: ComponentProps<typeof ProgressBar>['isIndeterminate'];
  }>(),
  { indeterminate: true }
);

const AtlasProgressBar = applyPureReactInVue(ProgressBar);
</script>

<template>
  <AtlasProgressBar :isIndeterminate="indeterminate" />
</template>
