import { createApp, nextTick } from 'vue';
import '@Styles/main.css';
import App from './App.vue';
import Libraries from '@Libraries';
import Plugins from '@Plugins';
import { router } from '@Libraries';
import * as Sentry from '@sentry/vue';
import LogRocket from 'logrocket';
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import { FetchError, posthog } from '#common';


const clickOutside = {
  // eslint-disable-next-line
  beforeMount: (el: HTMLInputElement, binding: any) => {
    // @ts-expect-error custom event
    // eslint-disable-next-line
    el.clickOutsideEvent = (event: any) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    // @ts-expect-error custom even defintion
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el: HTMLInputElement) => {
    // @ts-expect-error custom event definition
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};

const app = createApp(App);

const vueQueryPLuginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          if (failureCount >= 2) return false;
          if (error instanceof FetchError) {
            const { status } = error.response;
            const isClientError = status >= 400 && status < 500;
            if (isClientError) return false;
          }

          return true;
        },
      },
    },
  },
};
app.use(VueQueryPlugin, vueQueryPLuginOptions);

Libraries.forEach(({ lib, options }) => app.use(lib, options));
Plugins.forEach((plugin) => app.use(plugin));

// TODO : Move these instantiations to /libraries [AMFJ-46]

if (!import.meta.env.DEV) {
  LogRocket.init('ky5yba/asset-management-for-jira');

  Sentry.init({
    app,
    release: import.meta.env.VITE_APP_SENTRY_RELEASE,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['app.assetmanagementforjira.com'],
      }),
      posthog.sentryIntegration({
        organization: import.meta.env.VITE_APP_SENTRY_ORGANIZATION,
        projectId: import.meta.env.VITE_APP_SENTRY_PROJECT_ID,
      }),

    ],
    tracesSampleRate: parseFloat(import.meta.env.VITE_APP_TRACING_RATE || '0'),
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_APP_REPLAYS_RATE || '0'),
    replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_APP_REPLAYS_ERROR_RATE || '0'),
    tracePropagationTargets: ['app.assetmanagementforjira.com'],
  });

  const replay = Sentry.getReplay();
  replay?.startBuffering();

  // @ts-expect-error
  window.Beacon('on', 'open', () => {
    replay?.flush();
    const replayId = replay?.getReplayId();
    // @ts-expect-error
    window.Beacon('session-data', {
      // @ts-expect-error
      ...window.Beacon('info')?.visitor.sessionData,
      'Replay URL': `https://conelike.sentry.io/replays/${replayId}/`,
    });
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.setTag('sessionURL', sessionURL);

    // @ts-expect-error
    window.Beacon('session-data', {
      // @ts-expect-error
      ...window.Beacon('info')?.visitor.sessionData,
      'Session URL': sessionURL,
    });
  });
}

app.directive('click-outside', clickOutside);
app.mount('#app');

router.afterEach((to, from, failure) => {
  if (failure) return;

  nextTick(() => {
    posthog.capture('$pageview', { path: to.fullPath });
  });
});
