export enum RouteName {
  HOME = 'accessories-home',
  DETAILS = 'accessories-details',
  CREATE = 'accessories-create',
  EDIT = 'accessories-edit',
  FIELDS = 'accessories-fields',
}

export enum QueryKey {
  ACCESSORIES = 'accessories',
  ACCESSORY = 'accessory',
  HISTORY = 'accessories-history',
  COMMENTS = 'accessories-comments',
  FIELDS = 'accessories-fields',
  FIELD = 'accessories-field',
  ATTACHMENTS = 'accessories-attachments',
}

export enum PageTab {
  ACCESSORIES = 'accessories',
  FIELDS = 'fields',
}

export const PAGE_TABS = [
  { key: PageTab.ACCESSORIES, label: 'Accessories', name: RouteName.HOME },
  { key: PageTab.FIELDS, label: 'Fields', name: RouteName.FIELDS },
];

export enum AccessoryFieldType {
  TEXT = 'text',
  DROPDOWN = 'dropdown',
  NUMBER = 'number',
  DATE = 'date',
}

export const ACCESSORY_FIELD_TYPE_OPTIONS = [
  { value: AccessoryFieldType.TEXT, label: 'Text' },
  { value: AccessoryFieldType.DROPDOWN, label: 'Dropdown' },
  { value: AccessoryFieldType.NUMBER, label: 'Number' },
  { value: AccessoryFieldType.DATE, label: 'Date' },
];

export const FIELD_NAME_MAX_LENGTH = 256;

export const RESERVED_FIELD_NAMES = [
  'name',
  'total quantity',
  'location',
  'vendor',
  'order number',
  'purchase cost',
  'purchase date',
];
