<script setup lang="ts">
import { token } from '@atlaskit/tokens';
import {
  AtlasFlagGroup,
  AtlasFlag,
  AtlasAutoDismissFlag,
  AtlasSuccessIcon,
  AtlasErrorIcon,
  AtlasCode,
} from '#atlaskit';
import { useFlagsStore } from '../store';

const flagsStore = useFlagsStore();

const extractTokens = (description?: string) => {
  if (!description) return [];

  return description.split(/(\*\*.*?\*\*)/).map((token) => {
    if (token.startsWith('**')) {
      return { type: 'strong', string: token.replace(/\*\*/g, '') };
    }

    return { type: 'default', string: token };
  });
};
</script>

<template>
  <AtlasFlagGroup @dismissed="flagsStore.dismissFlag">
    <Component
      :is="flag.autoDismiss ? AtlasAutoDismissFlag : AtlasFlag"
      v-for="flag in flagsStore.flags"
      :id="flag.id"
      :key="flag.id"
      :title="flag.title"
      :actions="flag.actions"
    >
      <AtlasSuccessIcon
        v-if="flag.type === 'success'"
        :primary-color="token('color.icon.success')"
        label="success"
      />
      <AtlasErrorIcon
        v-else-if="flag.type === 'error'"
        :primary-color="token('color.icon.danger')"
        label="error"
      />

      <template #description>
        <span
          v-for="stringToken in extractTokens(flag.description)"
          :key="stringToken.string"
        >
          <strong v-if="stringToken.type === 'strong'">
            {{ stringToken.string }}
          </strong>
          <span v-else>
            {{ stringToken.string }}
          </span>
        </span>
        <div
          v-if="flag.error"
          class="code-container"
        >
          <AtlasCode>{{ flag.error }}</AtlasCode>
        </div>
      </template>
    </Component>
  </AtlasFlagGroup>
</template>

<style scoped>
.code-container {
  margin-top: 0.5em;
}

.code-container :deep(code) {
  color: currentColor;
}
</style>
