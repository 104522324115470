import { useQuery } from '@tanstack/vue-query';
import { useCurrentUser } from './useCurrentUser';
import { QueryKey } from '../constants';
import { api } from '../api';
import { inject, watch } from 'vue';
import { useJWT } from './useJWT';

export const useAuth = () => {
  const { atlassianId, query: currentUserQuery } = useCurrentUser();
  const { setToken } = useJWT();
  const store = inject('store');

  const query = useQuery({
    queryKey: [QueryKey.AUTH],
    queryFn: () => api.authenticate(atlassianId.value),
    staleTime: Infinity,
    enabled: currentUserQuery.isSuccess,
  });

  watch(query.data, (data) => {
    if (!data?.auth_token) return;

    setToken(data.auth_token);

    // old code compatibility
    store.auth.state.authentication = data;
  });

  return { query };
};
