export enum RouteName {
  DETAILS = 'asset-details',
  EDIT = 'asset-edit',
  COPY = 'asset-copy',
}

export enum QueryKey {
  ASSETS = 'assets',
  ASSET_COMMENTS = 'asset-comments',
  ASSET_HISTORY = 'asset-history',
  LINKED_ISSUES = 'linked-issues',
  ATTACHMENTS = 'asset-attachments',
}
