export enum RouteName {
  HOME = 'licenses-home',
  DETAILS = 'licenses-details',
  CREATE = 'licenses-new',
  EDIT = 'licenses-edit',
  FIELDS = 'licenses-fields',
}

export enum QueryKey {
  LICENSES = 'licenses',
  LICENSE = 'license',
  FIELDS = 'licenses-fields',
  FIELD = 'licenses-field',
  HISTORY = 'licenses-history',
  ATTACHMENTS = 'licenses-attachments',
}

export enum PageTab {
  LICENSES = 'licenses',
  FIELDS = 'fields',
}

export const PAGE_TABS = [
  { key: PageTab.LICENSES, label: 'Licenses', name: RouteName.HOME },
  { key: PageTab.FIELDS, label: 'Fields', name: RouteName.FIELDS },
];
