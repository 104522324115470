import { useMutation, useQuery } from '@tanstack/vue-query';
import { QueryKey } from '../constants';
import { api } from '../api';
import { DEFAULT_STALE_TIME_IN_MS } from '#common';
import { computed, watch } from 'vue';
import LogRocket from 'logrocket';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/vue';
import { useJWT } from './useJWT';

const ASSETS_PER_PAGE = 50;

export const useDetails = () => {
  const { hasToken } = useJWT();

  const query = useQuery({
    queryKey: [QueryKey.DETAILS],
    queryFn: () => api.getDetails(),
    staleTime: DEFAULT_STALE_TIME_IN_MS,
    enabled: hasToken,
  });

  watch(query.data, (data) => {
    if (!data) return;

    const installId = data.install_id.toString();

    window.Beacon('identify', {
      'install-url': data.install_url,
      'num-assets': data.num_assets,
      'num-asset-types': data.num_asset_types,
      'num-locations': data.num_locations,
      'install-dt': data.created_at,
    });
    LogRocket.identify(installId, { name: data.install_url, app: 'main' });
    posthog.identify(installId, { name: data.install_url });
    Sentry.setUser({ id: installId, username: data.install_url });
  });

  const assetsPagination = computed(() => {
    if (!query.data.value) return null;

    return {
      assets: query.data.value.num_assets,
      pageSize: ASSETS_PER_PAGE,
      pages: Math.ceil(query.data.value.num_assets / ASSETS_PER_PAGE),
    };
  });

  const postInstallMutation = useMutation({
    mutationFn: () => api.postInstall(),
  });

  return { query, assetsPagination, postInstallMutation };
};
