export enum QueryKey {
  REPORTS = 'reports',
  REPORT = 'report',
}

export enum RouteName {
  HOME = 'reports-home',
  NEW = 'reports-new',
  EDIT = 'reports-edit',
}

export enum BaseAssetField {
  ASSET_TYPE = 'Asset Type',
  LOCATION = 'Location',
  LOANEE = 'Loanee',
  ORDER_NUMBER = 'Order Number',
  PURCHASE_COST = 'Purchase Cost',
  PURCHASE_DATE = 'Purchase Date',
  VENDOR = 'Vendor',
  RENEWAL_DATE = 'Renewal Date',
}

export const EMPTY_FILTER_VALUE = '__empty__';
export const ACCESSORY_TYPE_ID = -1;
export const LICENSE_TYPE_ID = -2;

export const ORDER_FIELD_ID = -1;
export const PURCHASE_COST_FIELD_ID = -2;
export const PURCHASE_DATE_FIELD_ID = -3;
export const VENDOR_FIELD_ID = -4;
export const RENEWAL_DATE_FIELD_ID = -5;
